<!-- <app-navbar [footerBarMode]="currentEditCollectionFooterMode" (navbarButtonClick)="onNavbarBtnClick($event)" (footermodeChange)="onFootermodeChanged($event)"></app-navbar> -->
<app-webnav #navbarComponent [sourceCollection]="editCollectionSource" [navForSavedCollection]="isShowingSaveCollectionModeEnabled" (navbarButtonClick)="onWebEditCollectionNavBtnClick($event)" (webpanelActionClick)="handleNavpanelAction($event)"></app-webnav>

<div class="webedit-collection-page ">
</div>

<app-webaddfrom *ngIf="isCardSelectShown" #addFromLibrary [cardsToSelect]="cardSelectData" [disableForSelectCards]="editCollectionSource.collectionCards" (cardSelected)="onCardSeletected($event)" ></app-webaddfrom>

<!-- <app-card-select-panel [@fullscreenPanelAnimation] #cardSelectRef  *ngIf="isCardSelectShown" [title]="cardSelectTitle" [showCardPrice]="false" [cardsToSelect]="cardSelectData" [disableForSelectCards]="editCollectionSource.collectionCards" (cardSelected)="onCardSeletected($event)" (cardSelectSwitchPanelClick)="onCardSelectSwitchPanelClicked($event)"></app-card-select-panel> -->

<app-web-models-card *ngIf="isModelFeedShown" @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" [isLikeHidden]="!isShowingSaveCollectionModeEnabled" [feedModificator]="feedModificatorSetup" [startPosition]="cardFeedCardStartPosition" [feedIsVisible]="isModelFeedShown" [cardsRaw]="modelCardsMock" [feedMode]="CARDS_MODE.COMPOSITE_FEED" (actionBtnClicked)="onCardControlBtnClicked($event)" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)" ></app-web-models-card>
<app-web-collection-details #webCollectionDetailsComponent [sourceCollection]="editCollectionSource" [filterOptions]="filterCardMode" [collectionEditOptions]="collectionEditOptions" (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)"  (controlButtonClick)="onCollectionDetailsControlButtonClicked($event)"  >
  <app-card-thumbs-upload  class="model-thumb weblayout" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard"></app-card-thumbs-upload>
</app-web-collection-details>

<!-- <app-thumbnail-gallery [sourceCards]="editCollectionSource.collectionCards" (cardClicked)="onCollectionDetailsThumbClick($event)"  class="zero-margin last-slider" >
  <app-card-thumbs-upload  class="gallery-thumb" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard" [ngClass]="getLayoutClass()" fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload>
</app-thumbnail-gallery> -->

